<template>
        <div class="wrapper">
            <CRow>
                <CCol lg="12">
                    <CButton
                        @click="$router.go(-1)"
                        size="sm"
                        color="warning" 
                    > 
                        <font-awesome-icon icon="arrow-left"/> Go back
                    </CButton>
                </CCol>
            </CRow>
            <br>
            <CRow>
                <CCol lg="12">
                    <CCard>
                        <CCardHeader><font-awesome-icon icon="cog"/>  Manage Template
                            <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>

                            <!-- <template v-if="template.status == 'Active'"> -->
                                <CButton 
                                    size="sm"
                                    color="danger" 
                                    class="float-right" 
                                    @click="toggleEdit(), returnInformation()" 
                                    v-show="edit"
                                >
                                    <font-awesome-icon icon="window-close"/> Cancel
                                </CButton>

                                <CButton 
                                    :disabled="!isValid || !hasMadeChanges"
                                    v-if="$store.getters['can']('update-document-template')"
                                    size="sm"
                                    title="Either no changes has been made or has an invalid input."
                                    color="success" 
                                    class="float-right" 
                                    v-show="edit" 
                                    @click="updateTemplate()"
                                >
                                    <font-awesome-icon icon="save"/> Update
                                </CButton>

                                <CButton 
                                    v-if="$store.getters['can']('edit-document-template')"
                                    size="sm"
                                    color="info" 
                                    class="float-right" 
                                    @click="toggleEdit" 
                                    v-show="!edit"
                                >
                                    <font-awesome-icon icon="edit"/> Edit
                                </CButton>
                            <!-- </template> -->

                            <template v-if="template.status == 'Archived' && !edit">
                                <CButton 
                                    v-if="$store.getters['can']('restore-document-template')"
                                    size="sm"
                                    style="margin-right: 10px;"
                                    color="warning" 
                                    class="float-right" 
                                    @click="restore()"
                                >
                                <font-awesome-icon icon="trash-restore"/> Restore
                                </CButton>
                            </template>
                                
                            
                        </CCardHeader>
                        <CCardBody>
                            
                            <CRow>
                                <CCol lg="12">
                                    <h5>Status: <CBadge :color="getColorStatus(template.status)">{{template.status}}</CBadge></h5> 
                                </CCol>
                            </CRow>
                            <br>

                                <CRow>
                                    <CCol lg="6" md="6" xl="6">
                                        <CInput 
                                            :disabled="disable == 1 ? true : false"
                                            :lazy="false"
                                            :value.sync="$v.template.name.$model"
                                            :isValid="checkIfValid('name')"
                                            label="Name" 
                                            type="text" 
                                            placeholder="March-JO Template" 
                                            autocomplete="off"  
                                            v-tocapitalize
                                            v-model="template.name"
                                            invalidFeedback="Must be three(3) characters."
                                        />
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol lg="6" md="6" xl="6">
                                        <label>Category</label>
                                                <!-- :class="!group_required ? 'has-error' : 'has-success'" -->
                                            <v-select 
                                                label="category"
                                                :options="template_category"
                                                v-model="template.document_template_category"
                                                :class=" !disable ? !template.document_template_category ? 'has-error' : 'has-success' : ''"
                                                :disabled="disable == 1 ? true : false"
                                            />
                                    </CCol>
                                    
                                    <CCol lg="6" md="6" xl="6">
                                        <label>File API</label>
                                            <!-- :class="!group_required ? 'has-error' : 'has-success'" -->
                                        <v-select 
                                            label="name"
                                            :options="template_file_api"
                                            v-model="template.document_template_file_api"
                                            :disabled="disable == 1 ? true : false"
                                        />
                                    </CCol>
                                </CRow>
                                <br>
                                <CRow>
                                    <CCol lg="6" md="6" xl="6">
                                        <label>Matrix</label>
                                        <CRow>
                                            
                                            <CCol :lg="template.matrix && edit ? '11' : '12'" :style="template.matrix && edit ? 'padding-right: 0 !important;' : ''">
                                                <v-select 
                                                    :clearable="false"
                                                    label="name"
                                                    :options="template_matrices"
                                                    :disabled="disable == 1 ? true : false"
                                                    v-model="template.matrix"
                                                />
                                            </CCol>
                                            <CCol lg="1" style="padding-left: 2px !important;" v-show="template.matrix && edit">
                                                <CButton title="Clear selection." color="dark" class="cstm-btn" @click="clearMatrixSelection()">
                                                    <font-awesome-icon icon="times"/>
                                                </CButton>
                                            </CCol>
                                        </CRow>
                                            <!-- :class="!group_required ? 'has-error' : 'has-success'" -->
                                        
                                    </CCol>
                                    <CCol lg="6" md="6" xl="6">
                                        <label>Prompt</label>
                                        <v-select 
                                            label="name"
                                            :options="template_prompt_list"
                                            :disabled="disable == 1 ? true : false"
                                            v-model="template.document_template_prompt"
                                        />
                                    </CCol>
                                </CRow>
                                <br>
                                <CRow>
                                    <CCol lg="2">
                                        Signatory Locked
                                    </CCol>
                                    <CCol lg="10">
                                        <p-check :disabled="!edit" class="p-icon p-bigger" color="success" v-model="template.signatory_lock">
                                            <font-awesome-icon class="icon" icon="check"/>
                                        </p-check>
                                        <a-popover title="NOTE:" placement="topLeft">
                                            <template slot="content" >
                                            <p>Once this has been activated and used in routing, <br>remove, or change the signatory in the active routing. </p>
                                            </template>
                                                <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                                        </a-popover>
                                    </CCol>
                                </CRow>
                                <br>
                                <CRow>
                                    <CCol lg="12" md="12" xl="12">
                                        <CTextarea
                                            class="grp-text-area"
                                            :lazy="false"
                                            :value.sync="$v.template.description.$model"
                                            :isValid="checkIfValid('description')"
                                            :disabled="disable == 1 ? true : false"
                                            label="Description"
                                            placeholder="Content..."
                                            autocomplete="off"  
                                            v-model="template.description"
                                            v-tocapitalize
                                        />
                                    </CCol>
                                </CRow>
                        </CCardBody>
                    </CCard>
                    <CCard>
                        <CCardHeader><font-awesome-icon icon="signature"/>  Signatory & Requirements
                            <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                            <!-- <CButton 
                                size="sm"
                                :color="!show_archived_signatory ? 'success' : 'secondary'" 
                                class="float-right" 
                                @click="show_archived_signatory = !show_archived_signatory"
                            >
                                <font-awesome-icon 
                                :icon="!show_archived_signatory ? 'eye' : 'eye-slash'"/> {{!show_archived_signatory ?  'Show' : 'Hide'}} Archived
                            </CButton> -->
                        </CCardHeader>
                        <CCardBody>

                            
                            <CTabs>
                                
                                <br>
                                <CTab>
                                    <template slot="title">
                                        Active Signatory <CBadge color="success">( {{ [...new Set(active_signatory.map((item) => item.name))].length }} )</CBadge>
                                    </template>

                                    <CRow v-if="edit">
                                        <CCol xl="3" lg="3" md="3" sm="12">
                                            <b>Select signatory</b>
                                        </CCol>
                                        <CCol xl="2" lg="2" md="2" sm="12">
                                            <b>Purpose</b>
                                        </CCol>
                                        <CCol xl="1" lg="1" md="2" sm="12">
                                            <b>Order</b>
                                        </CCol>
                                        <CCol xl="2" lg="2" md="2" sm="12">
                                            <b>Reorder</b>
                                        </CCol>
                                        <CCol xl="2" lg="2" md="2" sm="12">
                                            <b>Signature</b>
                                        </CCol>
                                    </CRow>
                                    <CRow v-if="edit" style="margin-top:10px;">
                                        <CCol xl="3" lg="3" md="3" sm="12">
                                            <CInput 
                                                placeholder="John Doe"
                                                type="text" 
                                                autocomplete="off" 
                                                readonly 
                                                v-model="template_signatory.name"
                                            >
                                                
                                                <template #append>
                                                    <CButton color="primary"  @click="$refs.usersModal.users_modal = true, emitUserModal(), user_shared_routing = false">
                                                    <font-awesome-icon icon="search"/>
                                                    </CButton>
                                                </template>
                                            </CInput>
                                        </CCol>
                                        <CCol xl="2" lg="2" md="2" sm="12">
                                            <template v-if="template.matrix">
                                                <v-select 
                                                    label="purpose"
                                                    :options="matrix_purposes"
                                                    v-model="template_signatory.purpose_new"
                                                />
                                            </template>
                                            
                                            <template v-else>
                                                <CInput 
                                                    type="text" 
                                                    autocomplete="off"  
                                                    v-tocapitalize
                                                    v-model="template_signatory.purpose"
                                                />
                                            </template>
                                        </CCol> 
                                        <CCol xl="1" lg="1" md="1" sm="12">
                                            <CInput 
                                                type="text" 
                                                maxlength="2"
                                                onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'') "
                                                autocomplete="off"  
                                                v-model="template_signatory.order"
                                            />
                                        </CCol> 
                                        <CCol xl="1" lg="1" md="1" sm="12">
                                            <p-check 
                                                :disabled="order_adjustment_disabled"
                                                class="p-icon p-bigger" 
                                                color="success" 
                                                off-color="danger"
                                                toggle
                                                style="font-size: x-large; margin-top:7px;"
                                                v-model="template_signatory.order_adjust"
                                            >
                                                <!-- <font-awesome-icon class="icon"  icon="check"/> -->
                                                
                                                <font-awesome-icon class="icon" icon="check" slot="extra"/>
                                                <font-awesome-icon class="icon" icon="times" slot="off-extra"/>
                                                
                                                <label slot="off-label"></label>
                                            </p-check> 
                                        </CCol> 
                                        <CCol xl="1" lg="1" md="1" sm="12">
                                        </CCol>
                                        <CCol xl="1" lg="1" md="1" sm="12">
                                            <p-check 
                                                class="p-icon p-bigger" 
                                                color="success" 
                                                off-color="danger"
                                                toggle
                                                style="font-size: x-large; margin-top:7px;"
                                                v-model="template_signatory.required_signature"
                                            >
                                                <!-- <font-awesome-icon class="icon"  icon="check"/> -->
                                                
                                                <font-awesome-icon class="icon" icon="check" slot="extra"/>
                                                <font-awesome-icon class="icon" icon="times" slot="off-extra"/>
                                                
                                                <label slot="off-label"></label>
                                            </p-check> 
                                        </CCol> 
                                        <CCol xl="2" lg="2" md="2" sm="6" xs="6">
                                            <CButton
                                                block 
                                                color="warning" 
                                                @click="openRequirementsModal()"
                                                :disabled="(template.matrix ? !template_signatory.purpose_new : !template_signatory.purpose) || !template_signatory.order || !template_signatory.user_id"
                                            >
                                                Requirements
                                            </CButton>
                                        </CCol> 
                                        <CCol xl="1" lg="1" md="1" sm="6" xs="6">
                                            <CButton 
                                                block
                                                color="primary" 
                                                @click="addSignatory()"
                                                :disabled="(template.matrix ? !template_signatory.purpose_new : !template_signatory.purpose) || !template_signatory.order || !template_signatory.user_id"
                                            >
                                                <font-awesome-icon icon="plus"/>
                                            </CButton>
                                        </CCol>
                                    </CRow>

                                    
                                    <!-- <CRow v-if="edit" style="margin-top:10px;">
                                        <CCol xl="4" lg="4" md="4" sm="12">
                                            <CInput 
                                                placeholder="John Doe"
                                                type="text" 
                                                autocomplete="off" 
                                                readonly 
                                                v-model="template_signatory.name"
                                            >
                                                
                                                <template #append>
                                                    <CButton color="primary"  @click="$refs.usersModal.users_modal = true, emitUserModal(), user_shared_routing = false">
                                                    <font-awesome-icon icon="search"/>
                                                    </CButton>
                                                </template>
                                            </CInput>
                                        </CCol>
                                        <CCol xl="3" lg="3" md="3" sm="12">
                                            <CInput 
                                                type="text" 
                                                placeholder="Purpose"
                                                autocomplete="off"  
                                                v-tocapitalize
                                                v-model="template_signatory.purpose"
                                            />
                                        </CCol> 
                                        <CCol xl="2" lg="2" md="2" sm="12">
                                            <CInput 
                                                type="text" 
                                                placeholder="Order (1)"
                                                maxlength="2"
                                                onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'') "
                                                autocomplete="off"  
                                                v-model="template_signatory.order"
                                            />
                                        </CCol> 
                                        <CCol xl="2" lg="2" md="2" sm="6" xs="6">
                                            <CButton
                                                block 
                                                color="warning" 
                                                @click="openRequirementsModal()"
                                                :disabled="!template_signatory.purpose || !template_signatory.order || !template_signatory.user_id"
                                            >
                                                Requirements
                                            </CButton>
                                        </CCol> 
                                        <CCol xl="1" lg="1" md="1" sm="6" xs="6">
                                            <CButton 
                                                block
                                                color="primary" 
                                                @click="addSignatory()"
                                                :disabled="!template_signatory.purpose || !template_signatory.order || !template_signatory.user_id"
                                            >
                                                <font-awesome-icon icon="plus"/>
                                            </CButton>
                                        </CCol>
                                    </CRow> -->
                                    <CRow>
                                        <!-- <CCol lg="12" >
                                            <template v-if="edit && template.document_template_signatures.length > 1">
                                                <CButton 
                                                    size="sm"
                                                    color="danger" 
                                                    class="float-right" 
                                                    v-show="editOrder"
                                                    @click="editOrder = !editOrder"
                                                >
                                                    <font-awesome-icon icon="window-close"/> Cancel
                                                </CButton>

                                                <CButton 
                                                    size="sm"
                                                    color="success" 
                                                    class="float-right" 
                                                    v-show="editOrder" 
                                                    @click="confirmChangeOrder()"
                                                >
                                                    <font-awesome-icon icon="save"/> Confirm Changes
                                                </CButton>

                                                <CButton 
                                                    size="sm"
                                                    color="info" 
                                                    class="float-right" 
                                                    @click="editOrder = !editOrder" 
                                                    v-show="!editOrder"
                                                >
                                                    <font-awesome-icon icon="arrows-alt-v"/> Change signature order
                                                </CButton>
                                            </template>
                                        </CCol> -->
                                        <CCol lg="12">
                                            
                                            <!-- :items="show_archived_signatory ? template_signatories_all : template_signatories_active" -->

                                            <CDataTable
                                                :items="active_signatory"
                                                :fields="signatory_list_fields"
                                                :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                                :items-per-page="10"
                                                border
                                                items-per-page-select
                                                hover
                                                sorter
                                                size="sm"
                                                pagination
                                            >
                                                <template #name="{item, index}">
                                                    <td v-if="edit">
                                                        <v-select 
                                                            :clearable="false"
                                                            @option:selected="selectChangeNameOnList(item, index)"
                                                            :disabled="(item.is_active != '1' && item.id != null) || parseInt(item.signatory_in_drl_count) > 0"
                                                            :title="(item.is_active != '1' && item.id != null) ? 'Signatory inactive, cannot be change.' : ''"
                                                            label="name"
                                                            :options="user_signatories"
                                                            v-model="item.name"
                                                            :class="!item.name ? 'has-error' : 'has-success'"
                                                        />
                                                    </td>
                                                    <td v-else>
                                                        {{item.name}}
                                                    </td>
                                                </template>
                                                <template #purpose="{item}">
                                                    <td v-if="edit">
                                                        <center>
                                                            <template v-if="template.matrix || !template.matrix && created_with_matrix">
                                                                <v-select 
                                                                    label="purpose"
                                                                    :options="matrix_purposes"
                                                                    v-model="item.purpose_new"
                                                                />
                                                            </template>
                                                            <template v-else>
                                                                <CInput
                                                                    :disabled="item.is_active != '1' && item.id != null"
                                                                    :title="(item.is_active != '1' && item.id != null) ? 'Signatory inactive, cannot be change.' : ''"
                                                                    type="text" 
                                                                    autocomplete="off"  
                                                                    :value="item.purpose"
                                                                    v-model="item.purpose"
                                                                    :class="!item.purpose ? 'has-inp-error' : 'has-inp-success'"
                                                                />
                                                            </template>

                                                        </center>
                                                    </td>
                                                    <td v-else>
                                                        <center>
                                                            <template v-if="template.matrix">
                                                                {{ item.purpose_new.purpose }}
                                                            </template>
                                                            <template v-else>
                                                                {{item.purpose}}
                                                            </template>
                                                        </center>
                                                    </td>
                                                </template>
                                                <template #dates="{item}">
                                                    <template v-if="item.id != null">
                                                        <td> 
                                                            <small>
                                                                <strong>Date Created:</strong> {{ changeDateFormat(item.created_at) }} <br>
                                                                <strong>Date Updated:</strong> {{ changeDateFormat(item.updated_at) }}
                                                            </small>
                                                        </td>
                                                    </template>
                                                    <template v-else>
                                                        <td> 
                                                            <i>Recently Added</i>
                                                        </td>
                                                    </template>
                                                </template>
                                                <template #required_signature="{item}">
                                                    <td v-if="edit">
                                                        <center>
                                                            <p-check 
                                                                class="p-icon p-bigger" 
                                                                color="success" 
                                                                off-color="danger"
                                                                toggle
                                                                style="font-size: x-large; "
                                                                v-model="item.required_signature"
                                                            >
                                                                <!-- <font-awesome-icon class="icon"  icon="check"/> -->
                                                                
                                                                <font-awesome-icon class="icon" icon="check" slot="extra"/>
                                                                <font-awesome-icon class="icon" icon="times" slot="off-extra"/>
                                                                
                                                                <label slot="off-label"></label>
                                                            </p-check>
                                                        </center>
                                                    </td>
                                                    <td v-else>
                                                        <center>
                                                            <i>{{ item.required_signature ? 'Yes' : 'No' }}</i>
                                                        </center>
                                                    </td>
                                                </template>
                                                <template #order="{item}">
                                                    <td v-if="edit">
                                                        <center>
                                                            <CInput 
                                                                onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'')"
                                                                style="max-width: 50px;"
                                                                type="text" 
                                                                maxlength="2"
                                                                :value="item.order"
                                                                v-model="item.order"
                                                                :class="!item.order ? 'has-inp-error' : 'has-inp-success'"
                                                            />
                                                        </center>
                                                    </td>
                                                    <td v-if="!edit">
                                                        <center>
                                                            {{item.order}}
                                                        </center>
                                                    </td>
                                                </template>
                                                <template #status="{item}">
                                                    <td v-if="item.id != null">
                                                        <center>
                                                            <CBadge :color="getColorStatus(item.is_active == '1' ? 'Active' : 'Inactive')">
                                                                {{item.is_active == '1' ? 'Active' : 'Inactive'}}
                                                            </CBadge>
                                                        </center>
                                                    </td>
                                                    <td v-else>
                                                        <center>
                                                            <i>Recently Added</i>
                                                        </center>
                                                    </td>
                                                </template>
                                                <template #action="{item, index}">
                                                    <td>
                                                        <center>
                                                            <CButton 
                                                                v-if="item.id == null"
                                                                color="danger" 
                                                                @click="removeSignatory(item, index)" 
                                                                shape="pill" 
                                                                size="sm"
                                                            >
                                                                <font-awesome-icon icon="times"/> Remove
                                                            </CButton>

                                                            <CButton 
                                                                v-if="$store.getters['can']('document-template-delete-signatory') && item.id != null"
                                                                :color="parseInt(item.signatory_in_drl_count) == 0 ? 'danger' : 'dark'" 
                                                                @click="archiveSignatory(item, index)" 
                                                                shape="pill" 
                                                                size="sm"
                                                            >
                                                                <font-awesome-icon 
                                                                    :icon="parseInt(item.signatory_in_drl_count) == 0 ? 'times' : 'user-times'"
                                                                /> {{parseInt(item.signatory_in_drl_count) == 0 ? 'Remove' : 'Archive'}}
                                                            </CButton>
                                                        </center>
                                                    </td>
                                                </template>
                                                
                                                <template #requirements="{item, index}">
                                                    <td>
                                                        <center>
                                                            <!-- :disabled="item.is_active != '1' && item.id != null"
                                                            :title="(item.is_active != '1' && item.id != null) ? 'Signatory inactive, cannot be change.' : ''" -->
                                                            <CButton 
                                                                color="info" 
                                                                @click="viewRequirements(item, index), signatory_requirements_modal = true" 
                                                                shape="pill" 
                                                                size="sm"
                                                            >
                                                                <font-awesome-icon icon="eye"/> Requirements ( {{ item.document_signature_requirements.filter((item) => { return !item.deleted_at }).length }} )
                                                            </CButton>
                                                        </center>
                                                    </td>
                                                </template>
                                            </CDataTable>
                                        </CCol> 
                                    </CRow>
                                </CTab>
                                <CTab>
                                    
                                    <template slot="title">
                                        Inactive Signatory <CBadge color="danger">( {{ [...new Set(inactive_signatory.map((item) => item.name))].length }} )</CBadge>
                                    </template>
                                    <CRow>
                                        <!-- <CCol lg="12" >
                                            <template v-if="edit && template.document_template_signatures.length > 1">
                                                <CButton 
                                                    size="sm"
                                                    color="danger" 
                                                    class="float-right" 
                                                    v-show="editOrder"
                                                    @click="editOrder = !editOrder"
                                                >
                                                    <font-awesome-icon icon="window-close"/> Cancel
                                                </CButton>

                                                <CButton 
                                                    size="sm"
                                                    color="success" 
                                                    class="float-right" 
                                                    v-show="editOrder" 
                                                    @click="confirmChangeOrder()"
                                                >
                                                    <font-awesome-icon icon="save"/> Confirm Changes
                                                </CButton>

                                                <CButton 
                                                    size="sm"
                                                    color="info" 
                                                    class="float-right" 
                                                    @click="editOrder = !editOrder" 
                                                    v-show="!editOrder"
                                                >
                                                    <font-awesome-icon icon="arrows-alt-v"/> Change signature order
                                                </CButton>
                                            </template>
                                        </CCol> -->
                                        <CCol lg="12">
                                            
                                            <!-- :items="show_archived_signatory ? template_signatories_all : template_signatories_active" -->

                                            <CDataTable
                                                :items="inactive_signatory"
                                                :fields="signatory_list_fields"
                                                :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                                :items-per-page="10"
                                                border
                                                items-per-page-select
                                                size="sm"
                                                sorter
                                                pagination
                                            >
                                                <template #name="{item}">
                                                    <!-- <td v-if="edit">
                                                        <v-select 
                                                            @option:selected="selectChangeNameOnList(item, index)"
                                                            :disabled="item.is_active != '1' && item.id != null"
                                                            :title="(item.is_active != '1' && item.id != null) ? 'Signatory inactive, cannot be change.' : ''"
                                                            label="name"
                                                            :options="user_signatories"
                                                            v-model="item.name"
                                                            :class="!item.name ? 'has-error' : 'has-success'"
                                                        />
                                                    </td> -->
                                                    <td>
                                                        
                                                        {{item.name}}
                                                    </td>
                                                </template>
                                                <template #purpose="{item}">
                                                    <!-- <td v-if="edit">
                                                        <CInput
                                                            :disabled="item.is_active != '1' && item.id != null"
                                                            :title="(item.is_active != '1' && item.id != null) ? 'Signatory inactive, cannot be change.' : ''"
                                                            type="text" 
                                                            autocomplete="off"  
                                                            :value="item.purpose"
                                                            v-model="item.purpose"
                                                            :class="!item.purpose ? 'has-inp-error' : 'has-inp-success'"
                                                        />
                                                    </td> -->
                                                    <td>
                                                        <center>
                                                            <template v-if="item.tsmp_id">
                                                                {{item.purpose_new.purpose}}
                                                            </template>
                                                            <template v-else>
                                                                 {{item.purpose}}
                                                            </template>
                                                            
                                                        </center>
                                                    </td>
                                                </template>
                                                <template #dates="{item}">
                                                    <template v-if="item.id != null">
                                                        <td> 
                                                            <small>
                                                                <strong>Date Created:</strong> {{ changeDateFormat(item.created_at) }} <br>
                                                                <strong>Date Archive:</strong> {{ changeDateFormat(item.updated_at) }}
                                                            </small>
                                                        </td>
                                                    </template>
                                                    <template v-else>
                                                        <td> 
                                                            <i>Recently Added</i>
                                                        </td>
                                                    </template>
                                                </template>
                                                <template #order="{item}">
                                                    <!-- <td v-if="edit">
                                                        <CInput 
                                                            onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'')"
                                                            style="max-width: 50px;"
                                                            type="text" 
                                                            maxlength="2"
                                                            :value="item.order"
                                                            v-model="item.order"
                                                            :class="!item.order ? 'has-inp-error' : 'has-inp-success'"
                                                        />
                                                    </td> -->
                                                    <td>
                                                        <center>
                                                            {{item.order}}
                                                        </center>
                                                    </td>
                                                </template>
                                                <template #status="{item}">
                                                    <td v-if="item.id != null">
                                                        <center>
                                                            <CBadge :color="getColorStatus(item.is_active == '1' ? 'Active' : 'Inactive')">
                                                                {{item.is_active == '1' ? 'Active' : 'Inactive'}}
                                                            </CBadge>
                                                        </center>
                                                    </td>
                                                    <td v-else>
                                                        
                                                        <center>
                                                            <i>Recently Added</i>
                                                        </center>
                                                    </td>
                                                </template>
                                                <template #required_signature="{item}">
                                                    <td>
                                                        <center>
                                                            <i>{{ item.required_signature ? 'Yes' : 'No' }}</i>
                                                        </center>
                                                    </td>
                                                </template>
                                                <template #action="{item, index}">
                                                    <td>
                                                        <center>
                                                            <!-- <CButton 
                                                                v-if="item.id == null"
                                                                color="danger" 
                                                                @click="removeSignatory(item, index)" 
                                                                shape="pill" 
                                                                size="sm"
                                                            >
                                                                <font-awesome-icon icon="times"/> Remove
                                                            </CButton> -->

                                                            <CButton 
                                                                v-if="$store.getters['can']('document-template-restore-signatory')"
                                                                color="success" 
                                                                @click="restoreSignatory(item, index)" 
                                                                shape="pill" 
                                                                size="sm"
                                                            >
                                                                <font-awesome-icon 
                                                                    icon="user-plus"
                                                                /> Restore
                                                            </CButton>
                                                        </center>
                                                    </td>
                                                </template>
                                                
                                                <template #requirements="{item, index}">
                                                    <td>
                                                            <!-- :disabled="item.is_active != '1' && item.id != null"
                                                            :title="(item.is_active != '1' && item.id != null) ? 'Signatory inactive, cannot be change.' : ''" -->
                                                            <center>
                                                                <CButton 
                                                                    color="info" 
                                                                    @click="viewRequirements(item, index), signatory_requirements_modal = true" 
                                                                    shape="pill" 
                                                                    size="sm"
                                                                >
                                                                    <font-awesome-icon icon="eye"/> Requirements ( {{ item.document_signature_requirements.filter((item) => { return !item.deleted_at }).length }} )
                                                                </CButton>
                                                            </center>
                                                    </td>
                                                </template>
                                            </CDataTable>
                                        </CCol> 
                                    </CRow>
                                </CTab>
                            </CTabs>

                            
                            
                        </CCardBody>
                    </CCard>
                </CCol>
                
                <CCol lg="0">
                    <!-- <template v-if="$store.getters['can']('document-template-view-shared')">
                        <CCard>
                            <CCardHeader>
                                <font-awesome-icon icon="share-square"/>
                                Template Shared Routing
                                <a-popover title="NOTE:" placement="topRight">
                                    <template slot="content" >
                                    <p>If the user you wish to add as default shared does not exist in
                                        <br>the list, maybe this user already in the default shared list.</p>
                                    </template>
                                        <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                                </a-popover>
                                <CButton 
                                    size="sm"
                                    color="info" 
                                    @click="collapse_shared = !collapse_shared"
                                    class="float-right" 
                                >
                                    <font-awesome-icon 
                                        :icon="collapse_shared ? 'chevron-circle-down': 'chevron-circle-left'"
                                    />
                                </CButton>
                            </CCardHeader>
                            <CCollapse :show="collapse_shared">
                                <CCardBody>
                                    <CRow>
                                      
                                        <CCol lg="12">
                                            <CButton 
                                                v-if="$store.getters['can']('document-template-add-shared')"
                                                block
                                                color="success"  
                                                @click="showUserSelectableModal()">
                                                <font-awesome-icon icon="user"/> Add User
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                    <br>
                                    <CRow>
                                        <CCol lg="12">
                                            <CDataTable
                                                    :items="users_shared_template_list"
                                                    :fields="users_shared_template_list_fields"
                                                    :items-per-page="10"
                                                    border
                                                    sorter
                                                >
                                                <template #action="{item, index}">
                                                    <td>
                                                        <center>
                                                            <CButton 
                                                                
                                                                :disabled="shared_user_id_to_remove.includes(item.id)"
                                                                color="danger" 
                                                                @click="removeUserForSharedTemplate(item, index)" 
                                                                shape="pill" 
                                                                size="sm"
                                                            >
                                                                <font-awesome-icon :icon="shared_user_id_to_remove.includes(item.id) ? 'circle-notch' : 'times'" 
                                                                    :pulse="shared_user_id_to_remove.includes(item.id)"/> Remove
                                                            </CButton>
                                                        </center>
                                                    </td>
                                                </template>
                                            </CDataTable>
                                        </CCol>
                                    </CRow> 
                                </CCardBody>
                            </CCollapse>
                        </CCard>
                    </template> -->
                    
                    <!-- <template v-if="$store.getters['can']('document-template-view-attachment')">
                        <CCard>
                            <CCardHeader>
                                <font-awesome-icon icon="link"/>
                                Template Attachment Checklist
                                <font-awesome-icon style="color: #4caf50;" icon="info-circle" size="lg"/>
                                <CButton 
                                    size="sm"
                                    color="info" 
                                    @click="collapse_attachment = !collapse_attachment"
                                    class="float-right" 
                                >
                                    <font-awesome-icon 
                                        :icon="collapse_attachment ? 'chevron-circle-down': 'chevron-circle-left'"
                                    />
                                </CButton>
                            </CCardHeader>
                            <CCollapse :show="collapse_attachment">
                                <CCardBody>
                                    <template v-if="$store.getters['can']('document-template-add-attachment')">
                                        <CRow>
                                            <CCol lg="8">
                                                Name
                                            </CCol>
                                            <CCol lg="4">
                                                Required <a-popover title="NOTE:" placement="topRight">
                                                    <template slot="content" >
                                                    <p>When the attachment checklist is required, saving or starting <br>document routing is not possible without it.</p>
                                                    </template>
                                                        <font-awesome-icon style="color: #4caf50; " icon="info-circle"/>
                                                </a-popover>
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol lg="8">
                                                <CInput 
                                                    :class="!attachment.name ? 'has-inp-error' : 'has-inp-success'"
                                                    placeholder="PO Document"
                                                    type="text" 
                                                    autocomplete="off" 
                                                    v-model="attachment.name"
                                                    v-on:keypress.enter="!attachment.name ?  '' : addAttachmentChecklist()"
                                                >
                                                </CInput>
                                            </CCol>
                                            <CCol lg="2">
                                                <p-check 
                                                    class="p-icon p-bigger p-round" 
                                                    color="success" off-color="warning" toggle
                                                    v-model="attachment.required"
                                                >
                                                    <font-awesome-icon class="icon" icon="check" slot="extra"/>
                                                    Yes
                                                    <font-awesome-icon class="icon" icon="times" slot="off-extra"/>
                                                    <label slot="off-label">No</label>
                                                </p-check>  
                                            </CCol>
                                            <CCol lg="2">
                                                <CButton
                                                    :disabled="!attachment.name || attachment_adding"
                                                    class="float-right"
                                                    color="success"
                                                    @click="attachment.name ? addAttachmentChecklist() : ''"
                                                    :title="attachment.name ? 'Add Attachment Checklist.' : 'Attachment cannot be empty.'"
                                                >
                                                <font-awesome-icon :icon="attachment_adding ? 'circle-notch' : 'plus'" 
                                                            :pulse="attachment_adding"/>
                                                </CButton>
                                            </CCol>
                                        </CRow>
                                    </template>
                                    <CRow>
                                        <CCol lg="12">
                                            <CDataTable
                                                    :key="attachment_key"
                                                    :items="attachment_list_modified"
                                                    :fields="attachment_field_list"
                                                    :items-per-page="10"
                                                    border
                                                    sorter
                                                >
                                                <template #name={item}>
                                                    <td>
                                                        <CInput 
                                                            :class="!item.name ? 'has-inp-error' : 'has-inp-success'"
                                                            placeholder="PO Document"
                                                            type="text" 
                                                            autocomplete="off" 
                                                            v-model="item.name"
                                                        >
                                                        </CInput>
                                                    </td>
                                                </template>
                                                <template #required={item}>
                                                    <td>

                                                            <CSwitch 
                                                                color="success" 
                                                                variant="opposite" 
                                                                :checked.sync="item.required"
                                                                v-bind="labelTxt"  
                                                                size="sm"
                                                            />
                                                    </td>
                                                </template>
                                                
                                        
                                                <template #action="{item, index}">
                                                    <td>
                                                        <center>
                                                            <CButton 
                                                                v-if="item.required != item.required_backup || item.name != item.name_backup"
                                                                :disabled="is_updating_attachment && selected_attachment_id == item.id"
                                                                color="warning" 
                                                                title="Update Attachment"
                                                                shape="pill" 
                                                                size="sm"
                                                                @click="updateAttachmentChecklist(item, index)"
                                                            >
                                                                <font-awesome-icon :icon="is_updating_attachment && selected_attachment_id == item.id ? 'circle-notch' : 'pen-to-square'" 
                                                                :pulse="is_updating_attachment && selected_attachment_id == item.id"/>
                                                            </CButton>
                                                            &nbsp;
                                                            <CButton 
                                                                :disabled="checklist_id_to_remove.includes(item.id)"
                                                                color="danger" 
                                                                shape="pill" 
                                                                title="Remove Attachment"
                                                                size="sm"
                                                                @click="removeAttachmentChecklist(item, index)"
                                                            >
                                                                <font-awesome-icon :icon="checklist_id_to_remove.includes(item.id) ? 'circle-notch' : 'times'" 
                                                                :pulse="checklist_id_to_remove.includes(item.id)"/>
                                                            </CButton>
                                                        </center>
                                                    </td>
                                                </template>
                                            </CDataTable>
                                        </CCol>
                                    </CRow> 
                                </CCardBody>
                            </CCollapse>
                        </CCard>
                    </template> -->
                </CCol>

            </CRow>
            <CRow>
                <CCol lg="12">
                    <CRow v-if="template.status != 'Archived'">
                        <CCol lg="12">
                            <CCard borderColor="danger" v-if="$store.getters['can']('archive-document-template')">
                                <CCardHeader>
                                    <font-awesome-icon 
                                        icon="exclamation" 
                                        :style="{ color: 'red' }"
                                    /> 
                                    Danger Zone
                                </CCardHeader>
                                <CCardBody>
                                    <CRow>
                                        <CCol lg="8">
                                            <h6>Archive this Template ?</h6>
                                        </CCol>
                                        <CCol lg="12">
                                            <label> Once template is archived there's no turning back. Please be certain.</label>
                                        </CCol>
                                    </CRow>
                                    <br>
                                    <CRow>
                                        <CCol lg="3">
                                            <CButton shape="pill" color="warning" @click="archive()" > 
                                                <font-awesome-icon icon="minus-square" /> Archived 
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
            <CModal
                :show.sync="signatory_requirements_modal"
                color="primary"
                >
                <template #header>
                    <h6> <font-awesome-icon icon="comment-dots"/> Signatory Requirements for {{template_signatory.name || selected_signatory_details.name}} </h6>
                    <CButton 
                        class="pull-right" 
                        color="light" 
                        shape="pill" 
                        size="sm"
                        @click="signatory_requirements_modal = false"
                    >
                        <font-awesome-icon  size="sm" icon="times"/>
                    </CButton>
                </template>
                <template #footer>
                    <CRow lg="12">
                        <CButton 
                            class="pull-right" 
                            color="primary" 
                            @click="signatory_requirements_modal = false"
                        >
                            Close
                        </CButton>
                    </CRow>
                </template>
                <template #body-wrapper>
                    <CCardBody>
                        <CRow v-if="edit && (selected_signatory_details.is_active == '1' || selected_signatory_details.id == null) " >
                            <CCol xl="10" lg="10" md="10" sm="10">
                                <CInput 
                                    type="text" 
                                    placeholder="Total amount must be greater that 10,000?" 
                                    autocomplete="off"  
                                    v-model="template_signatory_requirements.requirement"
                                    v-tocapitalize
                                />
                            </CCol>
                            <CCol xl="10" lg="10" md="10" sm="10">
                                <CTextarea
                                    placeholder="Brief description about the requirements..."
                                    autocomplete="off"  
                                    v-model="template_signatory_requirements.description"
                                    v-tocapitalize
                                />
                            </CCol>
                            <CCol xl="2" lg="2" md="2" sm="2">
                                <CButton 
                                    :disabled="!template_signatory_requirements.requirement || !template_signatory_requirements.description"
                                    color="primary" 
                                    @click="addSignatoryRequirements()" >
                                    <font-awesome-icon icon="plus"/>
                                </CButton>
                            </CCol>
                        </CRow>
                        <CRow v-if="edit && selected_signatory_details.is_active != '1' && selected_signatory_details.id != null">
                            <CCol lg="12">
                                <h6> <i>Signatory inactive, cannot modify requirements. </i></h6>
                                <br>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol lg="12">
                                <CTabs>
                                    <br>
                                    <CTab>
                                        <template slot="title">
                                            Active <CBadge color="success">( {{ [...new Set(signatory_requirements_list.filter((item) => { return !item.is_archived }).map((item) => item.requirement))].length }} )</CBadge>
                                        </template>
                                        

                                        <CDataTable
                                            :key="signatory_requirements_key"
                                            :items="signatory_requirements_list.filter((item) => { return !item.is_archived })"
                                            :fields="signatory_requirements_list_fields"
                                            border
                                            sorter
                                            pagination
                                        >
                                        
                                            <!-- ="{item, index}" -->
                                            <template #status="{item}">
                                                <td v-if="!item.is_recently_added">
                                                    <CBadge :color="getColorStatus(item.is_archived ? 'Inactive' : 'Active')">
                                                        {{item.is_archived ? 'Inactive' : 'Active'}}
                                                    </CBadge>
                                                </td>
                                                <td v-else>
                                                    <i>Recently Added</i>
                                                    <!-- <CBadge :color="getColorStatus(item.is_archived ? 'Inactive' : 'Active')">
                                                        {{item.is_archived ? 'Inactive' : 'Active'}}
                                                    </CBadge> -->
                                                </td>
                                            </template>
                                            <template #action="{item, index}">
                                                <td>
                                                    <!-- <template v-if="!item.is_picked">
                                                        <CButton 
                                                            color="danger" 
                                                            @click="removeSignatoryRequirement(item, index)" 
                                                            shape="pill" 
                                                            size="sm"
                                                        >
                                                            <font-awesome-icon icon="times"/>
                                                        </CButton>
                                                    </template>
                                                    <template v-else> -->
                                                        <template v-if="$store.getters['can']('archived-and-restore-signatory-requirements')">
                                                            <CButton 
                                                                v-if="item.is_recently_added"
                                                                color="danger" 
                                                                @click="removeSignatoryRequirement(item, index)" 
                                                                shape="pill" 
                                                                size="sm"
                                                            >
                                                                <font-awesome-icon icon="times"/> Remove
                                                            </CButton>


                                                            
                                                            <CButton 
                                                                v-if="!item.is_archived && !item.is_recently_added"
                                                                :color="parseInt(item.requirement_picked_count) == 0 ? 'danger' : 'secondary'" 

                                                                :disabled="requirement_id_to_remove.includes(item.id)"
                                                                shape="pill" 
                                                                size="sm"
                                                                @click="archiveSignatoryRequirement(item, index)"
                                                            >
                                                                <font-awesome-icon 
                                                                    :icon="requirement_id_to_remove.includes(item.id) ? 'circle-notch' : parseInt(item.requirement_picked_count) == 0 ? 'times' : 'trash-alt' " 
                                                                    :pulse="requirement_id_to_remove.includes(item.id)"
                                                                /> 
                                                                {{parseInt(item.requirement_picked_count) == 0 ? 'Remove' : 'Archive'}}
                                                            </CButton>
                                                            
                                                            <CButton 
                                                                :disabled="requirement_id_to_restore.includes(item.id)"
                                                                v-if="item.is_archived && !item.is_recently_added"
                                                                color="success" 
                                                                @click="restoreSignatoryRequirement(item, index)" 
                                                                shape="pill" 
                                                                size="sm"
                                                            >
                                                                <font-awesome-icon :icon="requirement_id_to_restore.includes(item.id) ? 'circle-notch' : 'trash-restore' " :pulse="requirement_id_to_restore.includes(item.id)"/> Restore
                                                            </CButton>
                                                        </template>
                                                        <template v-else>
                                                            <div v-if="!item.is_recently_added">
                                                                <i>No permission to remove.</i>
                                                            </div>
                                                            <CButton 
                                                                v-if="item.is_recently_added"
                                                                color="danger" 
                                                                @click="removeSignatoryRequirement(item, index)" 
                                                                shape="pill" 
                                                                size="sm"
                                                            >
                                                                <font-awesome-icon icon="times"/> Remove
                                                            </CButton>
                                                        </template>
                                                    <!-- </template> -->
                                                    <!-- <CButton 
                                                        class="float-right"
                                                        color="secondary" 
                                                        @click="archiveSignatoryRequirement(item, index)" 
                                                        shape="pill" 
                                                        size="sm"
                                                    >
                                                        <font-awesome-icon icon="trash-alt"/> Arvhive
                                                    </CButton> -->
                                                </td>
                                            </template>
                                        </CDataTable>
                                    </CTab>
                                    <CTab>
                                        <template slot="title">
                                            Inactive <CBadge color="danger">( {{ [...new Set(signatory_requirements_list.filter((item) => { return item.is_archived }).map((item) => item.requirement))].length }} )</CBadge>
                                        </template>
                                        <CDataTable
                                            :key="signatory_requirements_key"
                                            :items="signatory_requirements_list.filter((item) => { return item.is_archived })"
                                            :fields="signatory_requirements_list_fields"
                                            border
                                            sorter
                                            pagination
                                        >
                                        
                                            <!-- ="{item, index}" -->
                                            <template #status="{item}">
                                                <td v-if="!item.is_recently_added">
                                                    <CBadge :color="getColorStatus(item.is_archived ? 'Inactive' : 'Active')">
                                                        {{item.is_archived ? 'Inactive' : 'Active'}}
                                                    </CBadge>
                                                </td>
                                                <td v-else>
                                                    <i>Recently Added</i>
                                                    <!-- <CBadge :color="getColorStatus(item.is_archived ? 'Inactive' : 'Active')">
                                                        {{item.is_archived ? 'Inactive' : 'Active'}}
                                                    </CBadge> -->
                                                </td>
                                            </template>
                                            <template #action="{item, index}">
                                                <td>
                                                    <!-- <template v-if="!item.is_picked">
                                                        <CButton 
                                                            color="danger" 
                                                            @click="removeSignatoryRequirement(item, index)" 
                                                            shape="pill" 
                                                            size="sm"
                                                        >
                                                            <font-awesome-icon icon="times"/>
                                                        </CButton>
                                                    </template>
                                                    <template v-else> -->
                                                        <template v-if="$store.getters['can']('archived-and-restore-signatory-requirements')">
                                                            <CButton 
                                                                v-if="item.is_recently_added"
                                                                color="danger" 
                                                                @click="removeSignatoryRequirement(item, index)" 
                                                                shape="pill" 
                                                                size="sm"
                                                            >
                                                                <font-awesome-icon icon="times"/> Remove
                                                            </CButton>


                                                            
                                                            <CButton 
                                                                v-if="!item.is_archived && !item.is_recently_added"
                                                                :color="parseInt(item.requirement_picked_count) == 0 ? 'danger' : 'secondary'" 

                                                                :disabled="requirement_id_to_remove.includes(item.id)"
                                                                shape="pill" 
                                                                size="sm"
                                                                @click="archiveSignatoryRequirement(item, index)"
                                                            >
                                                                <font-awesome-icon 
                                                                    :icon="requirement_id_to_remove.includes(item.id) ? 'circle-notch' : parseInt(item.requirement_picked_count) == 0 ? 'times' : 'trash-alt' " 
                                                                    :pulse="requirement_id_to_remove.includes(item.id)"
                                                                /> 
                                                                {{parseInt(item.requirement_picked_count) == 0 ? 'Remove' : 'Archive'}}
                                                            </CButton>
                                                            
                                                            <CButton 
                                                                :disabled="requirement_id_to_restore.includes(item.id)"
                                                                v-if="item.is_archived && !item.is_recently_added"
                                                                color="success" 
                                                                @click="restoreSignatoryRequirement(item, index)" 
                                                                shape="pill" 
                                                                size="sm"
                                                            >
                                                                <font-awesome-icon :icon="requirement_id_to_restore.includes(item.id) ? 'circle-notch' : 'trash-restore' " :pulse="requirement_id_to_restore.includes(item.id)"/> Restore
                                                            </CButton>
                                                        </template>
                                                        <template v-else>
                                                            <div v-if="!item.is_recently_added">
                                                                <i>No permission to remove.</i>
                                                            </div>
                                                            <CButton 
                                                                v-if="item.is_recently_added"
                                                                color="danger" 
                                                                @click="removeSignatoryRequirement(item, index)" 
                                                                shape="pill" 
                                                                size="sm"
                                                            >
                                                                <font-awesome-icon icon="times"/> Remove
                                                            </CButton>
                                                        </template>
                                                    <!-- </template> -->
                                                    <!-- <CButton 
                                                        class="float-right"
                                                        color="secondary" 
                                                        @click="archiveSignatoryRequirement(item, index)" 
                                                        shape="pill" 
                                                        size="sm"
                                                    >
                                                        <font-awesome-icon icon="trash-alt"/> Arvhive
                                                    </CButton> -->
                                                </td>
                                            </template>
                                        </CDataTable>
                                    </CTab>
                                </CTabs>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </template>
            </CModal>
            <UsersModal ref="usersModal" @selectedUser="userSelected($event)"/>
            <UsersSelectableModal ref="usersSelectableModal" @selectedUser="userMultipleSelected($event)"/>
        </div>
</template>
<script>
import UsersModal from '../../modals/UsersModal'
import UsersSelectableModal from '../../modals/UsersSelectableModal'
import { required,minLength } from "vuelidate/lib/validators"
export default {
    name: 'DocumentTemplateEdit',
    components: {
        UsersModal,
        UsersSelectableModal,
    },
    data() {
        return {
            created_with_matrix: false,
            template_matrices: [],
            matrix_purposes: [],

            selected_attachment_id: null,
            labelTxt: {
                labelOn: 'Yes',
                labelOff: 'No'
            },
            attachment_key: 0,
            order_adjustment_disabled: true,
            collapse_shared: false,
            collapse_attachment: false, 
            is_updating_attachment: false,
            
            signatory_requirements_key: 0,
            
            template_category: [],
            
            template_file_api: [],
            // show_archived_signatory: false,
            user_shared_routing: false,
            user_shared: {
                name: null,
                user_id: null
            },


            users_shared_template_list: [],

            attachment: {
                name: null,
                required: true,
            },

            checklist_id_to_remove: [],
            shared_user_id_to_remove: [],
            requirement_id_to_remove:[],
            requirement_id_to_restore:[],
            attachment_adding: false,

            attachment_list: [],
            
            order_input:[],

            editOrder: false,

            disable: 1,
            edit: false,
            selected_user_for_signatory: null,
            
            signatory_requirements_modal: false,

            signatory_requirement_view: false,

            selected_signatory_details_index: null,
            
            selected_signatory_details: {
                document_signature_id: null,
                name: null
            }, 
    
            template: {
                id: null,
                name: null,
                description: null,
                document_template_signatures: [],
                status: null,
                document_template_category: null,
                signatory_lock: false,
                template_signatory_matrix: null,
                template_prompt:null,
                matrix: null,
            },

            template_backup: {
                id: null,
                name: null,
                description: null,
                document_template_signatures: [],
                status: null,
                document_template_category: null,
                signatory_lock: false,
                matrix: null,
            },
            
            template_signatory: {
                user_id: null,
                name: null,
                purpose: null,
                order: null,
                order_adjust: false,
                required_signature: true,
            },
            
            template_signatory_requirements: {
                requirement: null,
                description: null,
            },

            signatory_requirements_list: [],

            recently_removed_signature: [],
            recently_removed_requirements: [],
            
            user_signatories:[],

            template_prompt_list:[],
            initial_prompt:[],

        }
    },
    validations: {
        template: {
            name: { required, minLength: minLength(3) },
            description: { required },
            document_template_category: { required },
            document_template_signatures: { required, minLength: minLength(1) }
        },
    },
    computed: {
        isValid () { return !this.$v.template.$invalid },
        isDirty () { return this.$v.template.$anyDirty },

       
        attachment_list_modified: function () {
            return this.attachment_list.map(p =>
                parseInt(p.required) == 1 ? { ...p, required: true, 
                                                    required_backup: true,
                                                    name_backup: p.name
                                            } : 
                                            { ...p, required: false, 
                                                    required_backup: false,
                                                    name_backup: p.name
                                            }
            )
        },

        signatory_list_fields: function () {
            let custom_fields= [
                { key: 'name',},
                { key: 'purpose' },
                { key: 'order' },
                { key: 'required_signature', 'label': 'Signature' },
                { key: 'requirements' },
                { key: 'dates' },
                // { key: 'status' },
            ]
            if(!this.disable) {
                custom_fields.push({ key: 'action', sorter: false})
            }
            return custom_fields;
        },

        attachment_field_list : function() {
            let custom_fields= [
                { key: 'name',},
                { key: 'required' },
                // { key: 'required_backup' }
            ]

            if(this.$store.getters.can('document-template-remove-attachment')) {
                custom_fields.push({ key: 'action', _style:'width:30%', sorter: false})
            }
            return custom_fields;
        },

        users_shared_template_list_fields: function() {
            let custom_fields= [
                { key: 'name', label: 'Template shared to'},
            ]

            if(this.$store.getters.can('document-template-remove-shared')) {
                custom_fields.push({ key: 'action', _style:'width:30%', sorter: false})
            }
            return custom_fields;
        },

        signatory_requirements_list_fields: function () {
            let custom_fields= [
                { key: 'requirement',},
                { key: 'description'},
                // { key: 'status' },
            ]
            if(!this.disable && (this.selected_signatory_details.is_active == '1' || this.selected_signatory_details.id == null)) {
                custom_fields.push({ key: 'action', sorter: false})
            }
            return custom_fields;
        },

        active_signatory: function () {
            return this.template.document_template_signatures.filter((item) => {
                return parseInt(item.is_active) == 1 || item.id == null
            })
        },

        inactive_signatory: function () {
            return this.template.document_template_signatures.filter((item) => {
                return parseInt(item.is_active) == 0
            })
        },

        hasMadeChanges: function() {
            return JSON.stringify(this.template_backup) == JSON.stringify(this.template) ? false : true;
        },
        
        // template_signatories_all: function (){
        //     return this.template.document_template_signatures;
        // }, 

        // template_signatories_active: function (){
        //     return this.template.document_template_signatures.filter(value => value.is_active !=0);
        // }
    },
    created () {
        this.template.id = this.$route.params.id
        this.$Progress.start()
        return this.getCategory().then(response_category => { // Category
            if(response_category.status == 200) {
                for(let i = 0; i < response_category.data.data.length; i++) {
                    let option = {
                        id: response_category.data.data[i].id,
                        category: response_category.data.data[i].category
                    }
                    this.template_category.push(option)
                }
                return this.getMatrix().then(response_matrix => {
                    if(response_matrix.status == 200) {
                        this.template_matrices = response_matrix.data.data
                        return this.getFileAPI().then(response_file_api => { // File API
                            if(response_file_api.status == 200) {
                                for(let i = 0; i < response_file_api.data.data.length; i++) {
                                    let option = {
                                        id: response_file_api.data.data[i].id,
                                        name: response_file_api.data.data[i].name
                                    }
                                    this.template_file_api.push(option)
                                }
                                return this.getUsers().then(response_users => {
                                    if(response_users.status == 200) {
                                        this.users = []
                                        for(let i = 0; i < response_users.data.data.length; i++) {
                                            let option = {
                                                user_id: response_users.data.data[i].id,
                                                name: `${response_users.data.data[i].first_name} ${response_users.data.data[i].last_name}${response_users.data.data[i].suffix ? ' '+response_users.data.data[i].suffix: ''}`,
                                            }
                                            this.user_signatories.push(option)
                                        }
                                        return this.getDocumentTemplatePrompts().then(response_prompt => {
                                            if (response_prompt.status == 200) {
                                                this.template_prompt_list = [];
                                                for (let i = 0; i < response_prompt.data.data.length; i++) {
                                                    let option = {
                                                        id: response_prompt.data.data[i].id,
                                                        name: response_prompt.data.data[i].name,
                                                    };
                                                    this.template_prompt_list.push(option);
                                                }
                                            }
                                            return this.getTemplate().then(response_template => { // Document Template
                                                if (response_template.status == 200) {
                                                    this.assignData(response_template.data.data);
                                                }
                                            });
                                        });
                                    }
                                })
                            }
                        })
                    }
                })


            }
        })
        // this.getCategory();
        // this.getFileAPI();
        // this.getDefaultShared();
        // this.getAttachmentChecklist();
        // this.getUsers();
        // this.getTemplate();        
    },
    methods: {
        clearMatrixSelection: function () {
            this.created_with_matrix = false
            this.template.matrix = null;
            this.matrix_purposes = [];
            this.template_signatory.purpose_new = null
            this.template.document_template_signatures = this.template.document_template_signatures.map(obj => {
                return {
                    ...obj, // Spread the existing object
                    purpose: obj.purpose_new?.purpose,
                    purpose_new: null, // Set the specific key to null
                };
            });


            // this.template.document_template_signatures = this.template.document_template_signatures.map((item) => {
            //     // if()
            //     item.fullName = item.name; // Copy 'name' value to 'fullName'
            //     return item; // Return the modified object
            // });
        },
        getMatrix: function () {
            // this.template_category = [];
            return new Promise((resolve, reject) => {
                axios.get('/drs/template-signatory-matrix/active-list', {validateStatus: () => true})
                .then(response => {
                    resolve (response)
                }).catch(function (err) {
                    console.log(err);
                })
            })
        },
        updateAttachmentChecklist: function (item, ind) {
            this.selected_attachment_id = item.id;
            this.attachment_key++;
            this.is_updating_attachment = true;
            let data = {
                _name: item.name,
                _required: item.required
            }
            axios.post('/drs/document-template-attachment-checklist/update/'+item.id, data, {validateStatus: () => true})
            .then(response => {
                if ( response.status == 200) {
                    this.is_updating_attachment = false;
                    this.attachment_list_modified[ind]['name_backup'] = data._name
                    this.attachment_list_modified[ind]['required_backup'] = data._required                    
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `${item.name} has been updated.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                }
            })
        },
        removeAttachmentChecklist: function (item, ind) {
            this.attachment_key++;
            this.checklist_id_to_remove.push(item.id);
            axios.post('/drs/document-template-attachment-checklist/remove/'+item.id, {validateStatus: () => true})
            .then(response => {
                if ( response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `${item.name} has been removed.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })

                    this.checklist_id_to_remove = this.checklist_id_to_remove.filter(object => object !== item.id)

                    for (let i = 0; i < this.attachment_list.length; i++) {
                        const element = this.attachment_list[i];
                        if(element.id == item.id) {
                            this.attachment_list.splice(i, 1);
                        }                            
                    }
                }
            })
        },
        removeUserForSharedTemplate: function (item, index) {
            this.shared_user_id_to_remove.push(item.id);
            if(!item.recently_added) {
                let _row = [{
                    id: item.id,
                    document_template_id: this.template.id,
                    shared_by_user_id: this.$store.getters['getUserDetails'].id,
                }];
                axios.post('/drs/document-template/shared/remove', _row, {validateStatus: () => true})
                .then(response => {
                    if(response.status == 200) {
                        this.shared_user_id_to_remove = this.shared_user_id_to_remove.filter(object  => object !== item.id)
                        
                        this.$swal({
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000,
                            icon: 'success',
                            title: `${item.name} has been removed.`,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                        
                        for (let i = 0; i < this.users_shared_template_list.length; i++) {
                            const element = this.users_shared_template_list[i];
                            if(element.id == item.id) {
                                this.users_shared_template_list.splice(i, 1);
                            }                            
                        }
                    }
                })
            }
            // this.users_shared_template_list.splice(index, 1)
        },
        getAttachmentChecklist: function ()  {
            return new Promise((resolve, reject) => {
                axios.post('/drs/document-template-attachment-checklist/list/'+this.template.id, {validateStatus: () => true})
                .then(response => {
                    resolve (response)
                }).catch(function (err) {
                    console.log(err);
                })
                // .then(response => {
                //     if ( response.status == 200) {
                //         this.attachment_list = response.data.data
                //     }
                // })
            })
        },
        addAttachmentChecklist: function () {
            this.attachment_key++;
            if(!this.attachment_adding) {
                this.attachment_adding = true
                let data = {
                    document_template_id: this.template.id, 
                    name: this.attachment.name,
                    required: this.attachment.required,
                }
                axios.post('/drs/document-template-attachment-checklist/add', data, {validateStatus: () => true})
                .then(response => {
                    if ( response.status == 200) {
                        this.$swal({
                            toast: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000,
                            icon: 'success',
                            title: `${this.attachment.name} has been added.`,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                        //ivan add
                        this.attachment_list.push({name: this.attachment.name, id: response.data.data, required: this.attachment.required ? "1" : "0"})
                        this.attachment.name = ''
                        this.attachment.required = true;
                        this.attachment_adding = false
                    } else {
                        this.attachment_adding = false
                    }
                })
            }
        },
        selectChangeNameOnList: function (item, index) {
            this.template.document_template_signatures.filter((object) => {
                if(item.name == object.name && item.order == object.order) {
                    object.user_id = item.name.user_id.toString();
                    object.name = item.name.name
                }
            })


            // let sub_list = this.active_signatory;
            // sub_list[index].user_id = item.name.user_id.toString();
            // sub_list[index].name = item.name.name

            // for (let i = 0; i < this.template.document_template_signatures.length; i++) {
            //     const element = this.template.document_template_signatures[i];

            //     if(element.name == item.name && element.order == item.order) {
            //         element.user_id = item.name.user_id.toString();
            //         element.name = item.name.name
            //         // this.template.document_template_signatures.splice(i, 1)
            //     }
                
            // }

            // let main_list = this.template.document_template_signatures;
            // main_list[index].user_id = item.name.user_id.toString();
            // main_list[index].name = item.name.name
        },  
        archiveSignatoryRequirement: function (item, index) {
            this.requirement_id_to_remove.push(item.id)
            axios.post('/drs/document-template/archive-requirement/'+ item.id, {is_picked: item.is_picked ? 1 : 0 }, {validateStatus: () => true})
            .then(response => {
                if ( response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `${item.requirement} has been ${item.is_picked ? 'archived.' : 'removed.'}`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }) 
                    
                    this.requirement_id_to_remove = this.requirement_id_to_remove.filter(object => object !== item.id)

                    if( item.is_picked ) {
                        this.selected_signatory_details.document_signature_requirements[index].deleted_at = new Date().toISOString()
                        this.template.document_template_signatures.filter((item) => {return  item.name == this.selected_signatory_details.name && item.order == this.selected_signatory_details.order && parseInt(item.is_active) == 1})[0].document_signature_requirements[index].deleted_at = new Date().toISOString()
                        this.template_backup.document_template_signatures.filter((item) => {return  item.name == this.selected_signatory_details.name && item.order == this.selected_signatory_details.order && parseInt(item.is_active) == 1})[0].document_signature_requirements[index].deleted_at = new Date().toISOString()
                        this.signatory_requirements_list[index].is_archived = new Date().toISOString()
                    } else {
                        this.selected_signatory_details.document_signature_requirements.splice(index, 1);
                        this.template.document_template_signatures.filter((item) => {return  item.name == this.selected_signatory_details.name && item.order == this.selected_signatory_details.order && parseInt(item.is_active) == 1})[0].document_signature_requirements.splice(index, 1);
                        this.template_backup.document_template_signatures.filter((item) => {return  item.name == this.selected_signatory_details.name && item.order == this.selected_signatory_details.order && parseInt(item.is_active) == 1})[0].document_signature_requirements.splice(index, 1);
                        this.signatory_requirements_list.splice(index, 1);
                    }
                }
            })
        },
        restoreSignatoryRequirement: function (item, index) {
            this.requirement_id_to_restore.push(item.id)
            axios.post('/drs/document-template/restore-requirement/'+ item.id, {validateStatus: () => true})
            .then(response => {
                if ( response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `${item.requirement} has been restored.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }) 
                    
                    this.requirement_id_to_restore = this.requirement_id_to_restore.filter(object => object !== item.id)
                    this.selected_signatory_details.document_signature_requirements[index].deleted_at = null
                    this.template.document_template_signatures.filter((item) => {return  item.name == this.selected_signatory_details.name && item.order == this.selected_signatory_details.order && parseInt(item.is_active) == 1})[0].document_signature_requirements[index].deleted_at = null
                    this.template_backup.document_template_signatures.filter((item) => {return  item.name == this.selected_signatory_details.name && item.order == this.selected_signatory_details.order && parseInt(item.is_active) == 1})[0].document_signature_requirements[index].deleted_at = null
                    this.signatory_requirements_list[index].is_archived = null
                }
            })
        },
        getUsers:function(){
            
            return new Promise((resolve, reject) => {
                axios.post('/users/list-with-parameter/', {validateStatus: () => true})
                .then(response => {
                    resolve (response)
                }).catch(function (err) {
                    console.log(err);
                })
                // .then(response => {
                //     if(response.status == 200) {
                //         this.users = []
                //         for(let i = 0; i < response.data.data.length; i++) {
                //             let option = {
                //                 user_id: response.data.data[i].id,
                //                 name: `${response.data.data[i].first_name} ${response.data.data[i].last_name}${response.data.data[i].suffix ? ' '+response.data.data[i].suffix: ''}`,
                //             }
                //             this.user_signatories.push(option)
                //         }
                //     }
                // })
            })
        },
        getFileAPI: function (){
            // this.template_file_api = [];
            return new Promise((resolve, reject) => {
                axios.get('/drs/document-template-file-api/list-for-other-modules', {validateStatus: () => true})
                .then(response => {
                    resolve (response)
                }).catch(function (err) {
                    console.log(err);
                })
                // .then(response => {
                //     if(response.status == 200) {
                //         for(let i = 0; i < response.data.data.length; i++) {
                //             let option = {
                //                 id: response.data.data[i].id,
                //                 name: response.data.data[i].name
                //             }
                //             this.template_file_api.push(option)
                //         }
                //     }
                // })
            })
        },
        getCategory: function() {
            this.template_category = [];
            return new Promise((resolve, reject) => {
                axios.get('/drs/document-template-category/active-list', {validateStatus: () => true})
                .then(response => {
                    resolve (response)
                }).catch(function (err) {
                    console.log(err);
                })
            })
            
        },
        getDefaultShared: function () {
            // if(this.$store.getters['can']('document-template-view-shared')) {
                
            return new Promise((resolve, reject) => {
                axios.get('/drs/document-template/shared/' + this.template.id, {validateStatus: () => true})
                .then(response => {
                    resolve (response)
                }).catch(function (err) {
                    console.log(err);
                })
                // .then(response => {
                //     if(response.status == 200) {
                //         this.users_shared_template_list.length = 0;
                //         let data = response.data.data
                //         for (let i = 0; i < data.length; i++) {
                //             let _row = {
                //                 id: data[i].id,
                //                 shared_by_user_id: data[i].shared_by_user_id,
                //                 added_by: `${data[i].shared_by_user.first_name} ${data[i].shared_by_user.last_name}${data[i].shared_by_user.suffix ? ' '+data[i].shared_by_user.suffix: ''}`, 
                //                 shared_to_user_id: data[i].shared_to_user_id,
                //                 name: `${data[i].shared_to_user.first_name} ${data[i].shared_to_user.last_name}${data[i].shared_to_user.suffix ? ' '+data[i].shared_to_user.suffix: ''}`,
                //                 recently_added: false, 
                //             }
                //         this.users_shared_template_list.push(_row)
                //         }
                //     }
                // })
            })
            // }
        },
        userMultipleSelected: function (object) {
            let _data = {
                document_template_id: this.template.id,
                share: []
            }
            if (object.length > 0) {
                for (let i = 0; i < object.length; i++) {
                   let _row = {
                        shared_by_user_id: this.$store.getters['getUserDetails'].id,
                        shared_to_user_id: object[i],
                   }
                   _data.share.push(_row)
                }
            }
            this.$Progress.start()
            axios.post('/drs/document-template/shared/add', _data, {validateStatus: () => true}).then(response => {
                if ( response.status == 200 ) {
                    this.$emit('show_users_selectable_modal_closed');
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `User(s) added successfully!`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }) 
                    
                    // this.users_shared_routing_list.push(_row)
                    
                    this.$Progress.finish()
                    this.getDefaultShared()

                }
            })
        },
        showUserSelectableModal: function () {
            let selected_id = [];
            

            /*
            * Code Block for preventing the signatory to be added in the shared documents
            */
            // let active_signatory = this.template.document_template_signatures.filter(x => {
            //     return parseInt(x.is_active) == 1;
            // })
            // selected_id = active_signatory.map(x => parseInt(x.user_id));
            // --------------


            this.users_shared_template_list.forEach(function(obj){
                selected_id.push(parseInt(obj.shared_to_user_id));
            })

            this.$refs.usersSelectableModal.users_selectable_modal = true,
            this.$emit('show_users_selectable_modal', selected_id);
        },
        archiveSignatory: function(item, index) {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: `You are trying to ${parseInt(item.signatory_in_drl_count) == 0 ? 'remove' : 'archive'} this signatory.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/drs/document-template/delete-signatory/'+ item.id, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 && response.data.message == 'Signatory has been deactivated.' ) {
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                showConfirmButton: false,
                                timer: 3000,
                                icon: 'success',
                                title: `${item.name} has been archived.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            }) 
                            this.$Progress.finish()
                            item.is_active = "0"

                            //backup
                            this.template_backup.document_template_signatures.filter((object, ind) => {
                                if(item.name == object.name && item.order == object.order) {
                                    this.template_backup.document_template_signatures[ind].is_active = "0";
                                }
                            })

                        } else if( response.status == 200 && response.data.message == 'Signatory has been deleted.' ) {
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                showConfirmButton: false,
                                timer: 3000,
                                icon: 'success',
                                title: `${item.name} has been removed to template.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                            this.$Progress.finish()

                            this.template.document_template_signatures.filter((object, ind) => {
                                if(item.name == object.name && item.order == object.order && item.is_active == object.is_active) {
                                    this.template.document_template_signatures.splice(ind, 1)
                                    // object.user_id = item.name.user_id.toString();
                                    // object.name = item.name.name
                                }
                            })


                            this.template_backup.document_template_signatures.filter((object, ind) => {
                                if(item.name == object.name && item.order == object.order) {
                                    this.template_backup.document_template_signatures.splice(ind, 1)
                                }
                            })
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        restoreSignatory: function (item, index) {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: "You are trying to restore this signatory.",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/drs/document-template/restore-signatory/'+ item.id, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                showConfirmButton: false,
                                timer: 3000,
                                icon: 'success',
                                title: `${item.name} has been restored.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            }) 
                            this.$Progress.finish()
                            item.is_active = "1"
                            this.template_backup.document_template_signatures[index].is_active = "1";
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        // addUserForSharedRouting: function () {
        //     if(!this.users_shared_routing_list.filter(e => e.user_id === this.user_shared.user_id).length > 0) {
        //         let _row = {
        //             user_id: this.user_shared.user_id,
        //             name: this.user_shared.name
        //         }

        //         this.user_shared.user_id = null;
        //         this.user_shared.name = null;

        //         this.users_shared_routing_list.push(_row)
        //     } else {
        //          return this.$swal({
        //             toast: true,
        //             position: 'top-right',
        //             showConfirmButton: false,
        //             timer: 3000,
        //             icon: 'error',
        //             title: `${this.user_shared.name} already exists.`,
        //             showConfirmButton: false,
        //             timerProgressBar: true,
        //         }) 
        //     }
        // },
        // userSharedRouting: function () {
        //     this.user_shared_routing = true;
        //     this.$refs.usersModal.users_modal = true, 
        //     this.emitUserModal()
        // },
        confirmChangeOrder: function () {
            let list = this.template.document_template_signatures;
            let order_value = [];
            for (let i = 0; i < list.length; i++) {
                const input_new_value = this.order_input[i];
                if(!input_new_value) {
                    return this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'error',
                        title: `Order field cannot be empty.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                }
                order_value.push(input_new_value)
            }
            // if(order_value.some(x => order_value.indexOf(x) !== order_value.lastIndexOf(x))) {
            //     return this.$swal({
            //         toast: true,
            //         position: 'center-end',
            //         showConfirmButton: false,
            //         timer: 3000,
            //         icon: 'error',
            //         title: `Order cannot be duplicate`,
            //     })
            // } else {        
                for (let i = 0; i < list.length; i++) {
                    const value = this.order_input[i];
                    list[i].order = value;
                }
            // }
            this.editOrder = false
            list.sort(function (x, y) {
                return x.order - y.order;
            });
        },
        userSelected: function (object) {
            if(!this.user_shared_routing) {
                this.selected_user_for_signatory = object
            } else {
                this.user_shared_routing= false,
                this.user_shared.name = object.name
                this.user_shared.user_id = object.id
            }
            // this.selected_user_for_signatory = object
        },
        checkIfValid: function (fieldName) {
            const field = this.$v.template[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        validateTemplate:function () {
            if( !this.template.name || !this.template.name || !this.template.document_template_category || this.template.document_template_signatures.length <= 0 ){
                return false;
            } 
            return true;
        },
        emitUserModal: function () {
            this.$emit('show_users_modal');
        },
        toggleEdit: function() {
            this.edit = !this.edit
            this.disable = (this.disable + 1) % 2
            if(!this.disable) { 
                this.$v.$touch()
                this.template_signatory.order_adjust = true;
            }
            else {
                this.$v.$reset()
                this.template_signatory.order_adjust = false;
            }
        },
        returnInformation: function () {
            this.recently_removed_signature = []
            this.recently_removed_requirements = []
            if(JSON.stringify(this.template_backup) != JSON.stringify(this.template)) {
               this.template = JSON.parse(JSON.stringify(this.template_backup))
            }
        },
        updateTemplate: function () {
            // console.log(this.template)
            if(JSON.stringify(this.template_backup) == JSON.stringify(this.template)) {
               this.$swal({
                    icon: "info",
                    title: "Opps!",
                    text: "No changes has been made.",
                })
                return;
            }

            // console.log(this.template.document_template_signatures)
            // return;

            // console.log(this.template.document_template_signatures)
            // return;
            
            for (let i = 0; i < this.template.document_template_signatures.length; i++) {
                const element = this.template.document_template_signatures[i];
                if(element.is_active == 1) {
                    if(!element.order) {
                        return this.$swal({
                            toast: true,
                            position: 'top-right',
                            timer: 3000,
                            icon: 'error',
                            title: `Signatory order field cannot be empty.`,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                    }
                    if(!element.purpose && !this.created_with_matrix) {
                        // console.log(element);
                        return this.$swal({
                            toast: true,
                            position: 'top-right',
                            timer: 3000,
                            icon: 'error',
                            title: `Signatory purpose field cannot be empty.`,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                    }
                    
                    if(!element.purpose_new && this.template.matrix) {
                        // console.log(element);
                        return this.$swal({
                            toast: true,
                            position: 'top-right',
                            timer: 3000,
                            icon: 'error',
                            title: `Signatory purpose field cannot be empty.`,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                    }
                    
                    if(!element.purpose_new && this.created_with_matrix) {
                        // console.log(element);
                        return this.$swal({
                            toast: true,
                            position: 'top-right',
                            timer: 3000,
                            icon: 'error',
                            title: `Signatory purpose field cannot be empty.`,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                    }
                    if(!element.name) {
                        return this.$swal({
                            toast: true,
                            position: 'top-right',
                            timer: 3000,
                            icon: 'error',
                            title: `Signatory name field cannot be empty.`,
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                    }
                }
            }


            if(this.validateTemplate()){
                this.template.recently_removed_requirements = JSON.parse(JSON.stringify(this.recently_removed_requirements))
                this.template.recently_removed_signature = JSON.parse(JSON.stringify(this.recently_removed_signature))

                let object = JSON.parse(JSON.stringify(this.template));


                // console.log(object)
                // return;
                
                delete object.deleted_at;
                delete object.document_template_category_id;
                delete object.document_template_file_api_id;
                delete object.tsm_id;
                delete object.prompt_id;

                
                if(this.template.document_template_file_api) {
                    object.document_template_file_api_id = this.template.document_template_file_api.id
                }
                if(this.template.document_template_prompt) {
                    object.prompt_id = this.template.document_template_prompt.id
                }
                object.document_template_category_id = object.document_template_category.id
                object.tsm_id = object.matrix?.id
                
                delete object.document_template_category;
                delete object.document_template_file_api;
                delete object.document_template_prompt_id;

                for (let i = 0; i < object.document_template_signatures.length; i++) {
                    const element = object.document_template_signatures[i];

                    for (let j = 0; j < element.document_signature_requirements.length; j++) {
                        const element2 = element.document_signature_requirements[j];
                        delete element2.document_routing_lines_count;
                        delete element2.created_at;
                        delete element2.deleted_at;
                        delete element2.updated_at;
                        delete element2.is_recently_added;
                    }

                    
                    let tsmp_id = element.purpose_new?.id
                    element.tsmp_id = tsmp_id ? tsmp_id : null
                    if(this.template.matrix) { // using new version
                        delete element.purpose_new;
                        // element.purpose = null;
                    } else { // using old version
                        element.tsmp_id = null 
                    }


                    if(element.name.name) {
                        let user_name = element.name.name
                        let user_id = element.name.user_id

                        element.name = user_name
                        element.user_id = user_id
                    }
                }

                const keys = ['name', 'order', 'is_active'],
                map = object.document_template_signatures.reduce((m, o) => {
                    const
                        key =  keys.map(k => JSON.stringify(o[k])).join('|'),
                        [count = 0, array = []] = m.get(key) || [];
                    return m.set(key, [count + 1, [...array, o]]);
                }, new Map),
                duplicates = Array
                .from(map.values(), ([count, array]) => count === 1 ? []: array)
                .flat();

                if(duplicates.length > 0) {
                    return this.$swal({
                        customClass: 'ta-left',
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        // icon: 'error',
                        html: `<div class="swal2-header">
                                    <ul class="swal2-progress-steps" style="display: none;"></ul>
                                    <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
                                        <span class="swal2-x-mark">
                                            <span class="swal2-x-mark-line-left"></span>
                                            <span class="swal2-x-mark-line-right"></span>
                                        </span>
                                    </div>
                                    <h5>Duplicate signatory with the same order found. <br> Different order can add the same signatory in the template.</h5>
                                </div>`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                }
                this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "You are trying to update the template.",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 
                        this.$Progress.start()
                        return axios.post('/drs/document-template/update/'+ this.template.id, object, {validateStatus: () => true})
                        .then(response => {
                            if ( response.status == 200 ) {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    html: `<b>${this.template.name}</b> Template has been Updated.`,
                                })
                                this.assignData(response.data.data)
                                this.toggleEdit();
                                this.$v.$reset()
                                this.$v.$touch()
                                this.$Progress.finish()
                            }
                        })
                    }
                }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                    }
                })
                return;
            }
            this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            return;
        },
        getTemplate: function () {
            
            return new Promise((resolve, reject) => {
                axios.get('/drs/document-template/' + this.template.id, {validateStatus: () => true})
                .then(response => {
                    resolve (response)
                }).catch(function (err) {
                    console.log(err);
                })
                // .then(response => {
                //     if(response.status == 200) {
                //         this.assignData(response.data.data)
                //     }
                // })
            })
        },
        getDocumentTemplatePrompts: function () {
            return new Promise((resolve, reject) => {
                axios.get('/drs/document-template-prompt/active-list', {validateStatus: () => true})
                .then(response => {
                        resolve (response)
                    }).catch(function (err) {
                        console.log(err);
                    })
            })
        },
        assignData: function (data) {
            if(data.matrix) {
                this.created_with_matrix = true; // this template is created with matrix
                this.matrix_purposes = data.matrix.template_signatory_matrix_purpose
                this.matrix_purposes.sort(function (x, y) {
                return x.sequence - y.sequence;
            }); 
            }
            this.template = data
            this.template.status = data.deleted_at ? 'Archived' : 'Active';
            this.template.signatory_lock = data.signatory_lock == 1 ? true : false;
            this.template_backup = JSON.parse(JSON.stringify(this.template))

            // if(this.template.document_template_signatures.length >= 0) {
            //     for (let index = 0; index < array.length; index++) {
            //         const element = array[index];
                    
            //     }
            //     this.order_input.push()
            // }
            this.$Progress.finish()
        },  
        addSignatory: function () {

            for (let i = 0; i < this.template.document_template_signatures.length; i++) {
                const element = this.template.document_template_signatures[i];
                if(element.name.name) {
                    let user_name = element.name.name
                    let user_id = element.name.user_id

                    element.name = user_name
                    element.user_id = user_id
                }
            }


            for (let i = 0; i < this.active_signatory.length; i++) {
                if(this.active_signatory[i].user_id == this.template_signatory.user_id && 
                    this.active_signatory[i].order == this.template_signatory.order) {
                    return this.$swal({
                        customClass: 'ta-left',
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 4000,
                        // icon: 'error',
                        html: `<div class="swal2-header">
                                    <ul class="swal2-progress-steps" style="display: none;"></ul>
                                    <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
                                        <span class="swal2-x-mark">
                                            <span class="swal2-x-mark-line-left"></span>
                                            <span class="swal2-x-mark-line-right"></span>
                                        </span>
                                    </div>
                                    <h5>${this.template_signatory.name} exists with the same order number. <br> Changing the order will allow duplicate signatory.</h5>
                                </div>`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }) 
                }
            }


            // for (let i = 0; i < this.template.document_template_signatures.length; i++) {
            //     if(this.template.document_template_signatures[i].user_id == this.template_signatory.user_id) {
            //         return this.$swal({
            //             toast: true,
            //             position: 'top-right',
            //             timer: 3000,
            //             icon: 'error',
            //             title: `${this.template_signatory.name} already exists.`,
            //             showConfirmButton: false,
            //             timerProgressBar: true,
            //         }) 
            //     }
            // }

            /*
            |--------------------------------------------------------------------------
            | Same order allowed. May 31, 2021 - 
            |--------------------------------------------------------------------------
            */      
            // for (let i = 0; i < this.template.document_template_signatures.length; i++) {
            //     if(this.template.document_template_signatures[i].order == this.template_signatory.order) {
            //         return this.$swal({
            //             toast: true,
            //             position: 'center-end',
            //             showConfirmButton: false,
            //             timer: 3000,
            //             icon: 'error',
            //             title: `Signatory Order ${this.template_signatory.order} already exists.`,
            //         }) 
            //     }
            // }

            let row = {
                id: null, // newly inserted
                is_active: "1", // newly inserted
                user_id: this.template_signatory.user_id,
                name: this.template_signatory.name,
                purpose: this.template_signatory.purpose,
                purpose_new: this.template_signatory.purpose_new,
                order: this.template_signatory.order,
                required_signature: this.template_signatory.required_signature,
                document_template_id: this.template.id,
                requirements: this.signatory_requirements_list.length > 0 ? true : false,
                document_signature_requirements: JSON.parse(JSON.stringify(this.signatory_requirements_list))
            };
            // console.log(row)

            this.template.document_template_signatures.push(row);

            // console.log(this.template.document_template_signatures)
            
           

            this.template_signatory.user_id = null;
            this.template_signatory.name = null;
            this.template_signatory.purpose = null;
            this.template_signatory.purpose_new = null;
            this.template_signatory.order = null;
            this.template_signatory.required_signature = true;
            this.$refs.usersModal.clear_selected = true;

            
            if(this.signatory_requirements_list.length > 0) this.signatory_requirements_list = []


            if(this.template_signatory.order_adjust) {
                let count_of_same_order;
                count_of_same_order = this.template.document_template_signatures.filter(x => x.order == row.order && x.is_active == "1");
                if(count_of_same_order.length == 2) {
                    for (let i = 0; i < this.template.document_template_signatures.length; i++) {
                        const element = this.template.document_template_signatures[i];
                        if(parseInt(element.order) >= parseInt(row.order) && element.user_id != row.user_id && element.is_active == "1") {
                            element.order++;
                        }
                    }
                }
            }

            this.template.document_template_signatures.sort(function (x, y) {
                return x.order - y.order;
            }); 

            this.template_signatory.order_adjust = false;
            this.order_adjustment_disabled = true;
            

        },
        removeSignatory: function (item, index) {
            this.template.document_template_signatures.filter((object, ind) => {
                if(item.name == object.name && item.order == object.order && item.is_active == object.is_active) {
                    this.template.document_template_signatures.splice(ind, 1)
                }
            })
            // for (let i = 0; i < this.template.document_template_signatures.length; i++) {
            //     const element = this.template.document_template_signatures[i];
            //     if(element.name == item.name && element.order == item.order) {
            //         this.template.document_template_signatures.splice(i, 1)
            //     }
            // }
            // this.active_signatory.splice(index, 1)
            // this.template.document_template_signatures.splice(index, 1)
            // if(this.template.document_template_signatures[index].id != null) {
            //     this.recently_removed_signature.push(item.id)
            // }
        },
        addSignatoryRequirements: function () {
            if(!this.template_signatory_requirements.requirement || !this.template_signatory_requirements.description) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            }
            
            let row = {
                id: null,
                document_signature_id: this.selected_signatory_details.id,
                requirement: this.template_signatory_requirements.requirement,
                description: this.template_signatory_requirements.description,
                // is_archived: null,
                // document_routing_lines_count: "0",
                is_recently_added: true,
            }


            if(this.signatory_requirement_view) { 

                this.template.document_template_signatures.filter((item) => {
                    return item.name == this.selected_signatory_details.name && item.order == this.selected_signatory_details.order && parseInt(item.is_active) == 1;
                })[0].document_signature_requirements.push(row)
                // for (let i = 0; i < this.template_backup.document_template_signatures.length; i++) {
                //                 const element = this.template.document_template_signatures[i];
                //                 if(element.name == item.name && element.purpose == item.purpose) {
                //                     this.template.document_template_signatures.splice(i, 1)
                //                 }
                //             }

                // this.template.document_template_signatures[this.selected_signatory_details_index].document_signature_requirements.push(row)
            } 
            
            this.signatory_requirements_list.push(row);

            this.template_signatory_requirements.requirement = null;
            this.template_signatory_requirements.description = null;
        },
        removeSignatoryRequirement: function (item, index) {
            if(this.signatory_requirement_view) {
                // let list = this.template.document_template_signatures[this.selected_signatory_details_index].document_signature_requirements;
                // for (let i = 0; i < list.length; i++) {
                if(item.id) {
                    this.recently_removed_requirements.push(item.id)
                }
                // }

                this.template.document_template_signatures.filter((object, ind) => {
                    return object.name == this.selected_signatory_details.name && object.order == this.selected_signatory_details.order && parseInt(item.is_active) == 1;
                })[0].document_signature_requirements.splice(index, 1)

                // this.template.document_template_signatures[this.selected_signatory_details_index].document_signature_requirements.splice(index, 1)
            }
            this.signatory_requirements_list.splice(index, 1)
        },
        openRequirementsModal:function() {
            this.signatory_requirements_key += 1
            this.signatory_requirements_modal = true; 
            this.signatory_requirements_list = []
        },
        viewRequirements: function(item, index) {
            this.signatory_requirements_key += 1
            this.selected_signatory_details = JSON.parse(JSON.stringify(item));
            this.signatory_requirement_view = true;
            this.selected_signatory_details_index = index
            this.signatory_requirements_list = []

            for (let i = 0; i < item.document_signature_requirements.length; i++) {
                let row = {
                    id: item.document_signature_requirements[i].id,
                    requirement: item.document_signature_requirements[i].requirement,
                    description: item.document_signature_requirements[i].description,
                    is_picked: parseInt(item.document_signature_requirements[i].document_routing_lines_count) > 0 ? true : false, 
                    is_recently_added: item.document_signature_requirements[i].created_at ? false : true, 
                    is_archived: item.document_signature_requirements[i].deleted_at,
                    requirement_picked_count: item.document_signature_requirements[i].document_routing_lines_count 
                }
                this.signatory_requirements_list.push(row);
            }
        },
        archive: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: `You are trying to archive the ${this.template.name}`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                }).then((result) => {
                if(result.value){
                    this.$Progress.start()
                    axios.post('/drs/document-template/archive/' + this.template.id, {validateStatus: () => true})
                    .then(response => {
                        if(response.status == 200) {
                            
                            this.assignData(response.data.data)
                            this.toggleEdit()
                            this.$Progress.finish()
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                timer: 3000,
                                icon: 'success',
                                title: `${this.template.name} successfully archived.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                        }
                    })
                }else{
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        restore: function () {
            this.$Progress.start()
            axios.post('/drs/document-template/restore/' + this.template.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.assignData(response.data.data)
                    this.$Progress.finish()
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${this.template.name} restored successfully.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                }
            })
        },
    },
    watch: {
        'template.matrix': function (value) {
            if(value) {
               this.matrix_purposes = value.template_signatory_matrix_purpose
            }
        },
        selected_user_for_signatory: {
            handler(newValue, oldValue) {
                this.template_signatory.name = newValue.name
                this.template_signatory.user_id = newValue.id
            },  
            deep: true
        },
        signatory_requirements_modal: function(value) {
            if(!value && this.signatory_requirement_view) {
                this.signatory_requirement_view = false
                this.signatory_requirements_list = []
            } else if(value && !this.signatory_requirement_view) {
                this.selected_signatory_details.id= null
                this.selected_signatory_details.name = null
                this.selected_signatory_details.document_signature_id = null;
            }

        },
        'template_signatory.order': function (value) {
            if(value) {
                if (!/^\d+$/.test(value)) {
                    this.template_signatory.order = null
                    this.template_signatory.order = ""
                }

                if(parseInt(this.template_signatory.order) == 1) {
                    this.order_adjustment_disabled = true;
                    this.template_signatory.order_adjust = false;
                    return 
                }

                let duplicates = this.template.document_template_signatures.filter(x => parseInt(x.order) == parseInt(this.template_signatory.order) && parseInt(x.is_active) == 1);

                if(duplicates.length == 1) {
                    this.order_adjustment_disabled = false;  
                    this.template_signatory.order_adjust = true; 
                } else {
                    this.order_adjustment_disabled = true;
                    this.template_signatory.order_adjust = false;
                }

            }
        },
        'template.document_template_signatures': function (value) {
            this.order_input = []
            for (let index = 0; index < value.length; index++) {
                this.order_input.push(parseInt(value[index].order))
            }
        }
    }
}
</script>